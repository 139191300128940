import React from 'react'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import {
  Container,
  Title,
  Subtitle,
  // Image,
  Columns,
  Column,
  Section,
  Box,
} from 'bloomer'

const Offerings = ({ items }) => (
    <Section className='offerings'>
      <Container className="container--narrow">
        <Columns isMultiline>
          {items.map((item, id) => (
            <Column key={id} isSize={{ desktop: 4, default: 12 }}>
              <Box style={{ maxWidth: '17rem' }}>
                {/* <Columns isVCentered isMobile> */}
                  {/* <Column isSize="narrow"> */}
                    <Img fixed={item.image.fixed} style={{ marginBottom: '.5rem' }} />
                    {/* <Image src={item.image} /> */}
                  {/* </Column> */}
                  {/* <Column> */}
                    <Title isSize="4" tag="h3">
                      {item.title}
                    </Title>
                    <Subtitle tag="h4">{item.subtitle}</Subtitle>
                    <Link className="button is-primary" to={item.link}>
                      Learn More
                    </Link>
                  {/* </Column> */}
                {/* </Columns> */}
              </Box>
            </Column>
          ))}
        </Columns>
      </Container>
    </Section>
  )

export default Offerings
