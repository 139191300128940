import React from 'react'
import Layout from '../components/Layout'
import { graphql } from 'gatsby'
import SEO from '../components/SEO'
import Offerings from '../components/Offerings'
import { Hero, HeroBody, Container, /*Title,*/ Subtitle } from 'bloomer'

export default ({ data }) => {
  const offerings = [
    {
      title: 'Dog Boarding',
      subtitle: `Starting at $23 a night`,
      image: data.offering1.thumbnail,
      link: '/dog-boarding',
    },
    {
      title: 'Cat Boarding',
      subtitle: `Starting at $12 a night`,
      image: data.offering2.thumbnail,
      link: '/cat-boarding',
    },
    // {
    //   title: 'Dog Walking',
    //   subtitle: `We offer dog walking services for dogs of all sizes and energy levels.`,
    //   image: data.offering3.thumbnail,
    //   link: '/dog-walking',
    // },
    {
      title: 'Dog Grooming',
      subtitle: `For dogs of all sizes.`,
      image: data.offering4.thumbnail,
      link: '/dog-grooming',
    },
  ]

  return (
    <Layout>
      <SEO title="Home" alias="home" />
      <Hero
        isColor="primary"
        isSize="medium"
        style={{
          backgroundImage:
            // 'url(https://shadylaneboardingkennel.com/wp/wp-content/uploads/2013/09/DSC_0185-2.jpg)',
            // `url(${data.hero.small.fixed.src})`,
            `url(${data.hero.large.fixed.src})`,
        }}
        className="hero--home"
      >
        <HeroBody>
          <Container className="container--narrow">
            {/* <Title isSize={2}>
              <span class="title-line title-line--1">A kennel with heart,</span>
              <br />
              <span class="title-line title-line--2">
                in the heart of the country
              </span>
            </Title> */}
            <Subtitle isSize={4}>
              We provide compassionate, professional boarding for dogs and cats.
            </Subtitle>
          </Container>
        </HeroBody>
      </Hero>
      <Offerings items={offerings}/>
    </Layout>
  )
}

export const query = graphql`
  query {
    hero: file(relativePath: { eq: "hero2.jpg" }) {
      small: childImageSharp {
        fixed(width: 600, height: 200) {
          ...GatsbyImageSharpFixed
        }
      }
      medium: childImageSharp {
        fixed(width: 1000, height: 300) {
          ...GatsbyImageSharpFixed
        }
      }
      large: childImageSharp {
        fixed(width: 1400, height: 500) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    offering1: file(relativePath: { eq: "dog-boarding/image3.jpg" }) {
      thumbnail: childImageSharp {
        fixed(width: 250, height: 190) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    offering2: file(relativePath: { eq: "cat-boarding/image3.jpg" }) {
      thumbnail: childImageSharp {
        fixed(width: 250, height: 190) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    offering3: file(relativePath: { eq: "dog-walking/image2.jpg" }) {
      thumbnail: childImageSharp {
        fixed(width: 250, height: 190) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    offering4: file(relativePath: { eq: "dog-grooming/cover.jpg" }) {
      thumbnail: childImageSharp {
        fixed(width: 250, height: 190) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`
